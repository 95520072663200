import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import kt11 from '../../../images/LS1.gif'
import kt12 from '../../../images/LS2-300x82.gif'
import kt13 from '../../../images/LS3-300x106.gif'
import kt14 from '../../../images/LS4-300x77.gif'


const kogunemiseTyybid2 = () => (
  <Layout>
    <SEO title="Voldikseinte kogunemise tüübid" />
    
    <div className="header-bottom">
      <div className="bg-color-bottom">
        <div className="header-bottom-text">
          <div className="intro-para text-center quote">
              <h1>KOGUNEMISE TÜÜBID</h1>
              <hr className="footer-line"></hr>
          </div>
        </div>
      </div>
   </div>
   
   <div className="epoporandad" style={{
          maxWidth: 1300,
          margin: 'auto',
        }}>

        <div className="wrapper-epo">
            <Link to='/ruumijagajad/voldikuksed/'>
            <div className="epo">
                <h2>Voldikuksed</h2>
            </div>
            </Link>
            <Link to='/ruumijagajad/lootsuksed/'>
            <div className="epo">
                <h2>Lõõtsuksed</h2>
            </div>
            </Link>
            <Link to='/ruumijagajad/siirdeseinad/'>
            <div className="epo">
                <h2>Siirdeseinad</h2>
            </div>
            </Link>
            <Link to='/ruumijagajad/voldikseinad/'
            activeStyle={{ borderBottom: '3px solid #ffa609' }}
            partiallyActive={true}> 
            <div className="epo">
                <h2>Voldikseinad</h2>
            </div>
            </Link>
            <Link to='/ruumijagajad/referentsid/'>
            <div className="epo">
                <h2>Referentsid</h2>
            </div>
            </Link>
         </div>


        <div className="kt-wrapper2">

            <h3>Neli erinevat kogunemistüüpi</h3>
            <p>Saad valida nelja erineva kogunemistüübi vahel olenevalt sellest, millised on ruumid ja millised on 
            ruumi tehnilised võimalused seinade paigaldamiseks. Voldikseina elemendid ripuvad kas keskributusega
            või juhtlatiga nihkes- servriputusega. Servriputusega seinad saadaval ainult koos alumise juhtlatiga,
            keskriputusega ainult ülemise juhtlatiga.</p>

        </div>

  </div>


  <div className="vs-grid"> 

        <div>
            <h3>Üheosaline, keskriputusega voldiksein</h3>
            <p>Koguneb juhtlati ühte serva, rullikud elemendi keskel, 
            äärmine element toimib läbikäigu uksena.</p>    
            <img src={kt11} alt="graafik1"></img>
        </div>   

        <div>
            <h3>Keskelt avanev (kaheosaline), keskriputusega voldiksein</h3>
            <p>Koguneb juhtlati mõlemasse serva, rullikud elemendi keskel,
            keskmised elemendid toimivad paaris-ustena..</p>    
            <img src={kt12} alt="graafik2"></img>
        </div> 

        <div>
            <h3>Üheosaline, servriputusega voldiksein</h3>
            <p>Koguneb juhtlati ühte serva, rullikud elemendi servas,
            äärmine element toimib läbikäigu uksena.</p>    
            <img src={kt13} alt="graafik3"></img>
        </div>  
        <div>
            <h3>Kaheosaline, servriputusega voldiksein</h3>
            <p>Koguneb juhtlati mõlemasse serva, rullikud elemendi servas,
            keskmised elemendid toimivad paaris-ustena.</p>    
            <img src={kt14} alt="graafik4"></img>
        </div>  



  </div>


  </Layout>
)

export default kogunemiseTyybid2